import React from 'react';

import { loadCSS } from '../../../../utils/loadCSS';
import '../../../../assets/styles/kpl.css';

loadCSS('kpl-site');

export const KplSiteWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <>{children}</>;
};
export default KplSiteWrapper;
